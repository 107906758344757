import React from 'react';
import { Select, Button, Input } from '@chakra-ui/react';

const UnSubscribe = () => {
  return (
    <div className="h-unsubsribe px-3 flex items-center bg-ec justify-center">
      <div className="bg-white rounded-lg lg:w-8/12 cust-shadow lg:px-24 lg:py-16 px-4 py-8">
        <div className="text-center bordbl px-4 lg:px-8 pb-8">
          <h5 className=" font-semibold fsize20 sm-fsize18">
            yash.singh@aiab.in
          </h5>
          <p className=" font-normal fsize16 sm-fsize14">
            is subscribed to our mailing list(s).
          </p>
        </div>
        <div className="text-center px-4 lg:px-8 py-6">
          <h6 className="text-danger font-semibold fsize30 sm-fsize20">
            Unsubscribed from our mailing list
          </h6>
          <p className=" font-normal mt-2 fsize16 sm-fsize12">
            To help us improve our services, we would be grateful if you could
            tell us why:
          </p>
          <div className="lg:w-8/12 mt-4 lg:mt-10 flex justify-center mx-auto">
            <div>
              <Select placeholder="select" className="">
                <option value="option1">
                  Your emails are not relevant to me
                </option>
                <option value="option2">Your emails are too frequent</option>
                <option value="option2">
                  I don't remember signing up for this
                </option>
                <option value="option2">
                  I no longer want to receive these email
                </option>
                <option value="option2">
                  The emails are spam and should be reported
                </option>
                <option value="option2">Others</option>
              </Select>
              <Input placeholder="Enter" className="mt-2" />
            </div>
          </div>
          <Button colorScheme="primary" className="mt-4 lg:mt-8">
            Unsubscribe
          </Button>
        </div>
      </div>
    </div>
  );
};

export default UnSubscribe;
